import logo from './logo.svg';
import bg from './BG.svg';
import './App.css';
// import Thing from "./Marquee";
// import Blocks from "./Blocks";


function App() {
  return (
    <div className="App">
      <div className='MQcont'>
        <div className='mainText'>
        - HIRING -
          <br></br>
          <br></br>
          & POSITION 1 &
          <br></br>
          <br></br>
          CLO3D OR MARVELOUS DESIGNER EXPERT FOR CLOTHING ANIMATION RENDERS (URGENT) (SHORT TERM)
          <br></br>
          <br></br>
          & POSITION 2 &
          <br></br>
          <br></br>
          FRONTEND DEV FOR EDENHORDE GAME (MUST HAVE SOME BLOCKCHAIN EXP) REACT & NEXTJS (LONG TERM)
          <br></br>
          <br></br>
          & POSITION 3 &
          <br></br>
          <br></br>
          FRONTEND DEV TO WORK ON STEALTH PROJECT (MUST HAVE BLOCKCHAIN EXP) REACT & WEB (SHORT TERM)
          <br></br>
          <br></br>
        {/* USE THIS MOMENT IN WEB3 TO MAKE THINGS THAT BRING JOY.<br></br>FOCUS ON A FEELING NOT REVENUE.<br></br>DON'T WASTE THIS OPPORTUNITY. */}
        <img alt="" className='toplogo' src={logo} />
        </div>
        <img alt="" className='bg' src={bg} />
        </div>
{/* <Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/>
<Thing/>
<Blocks/> */}
{/* <Thing/> */}
      <img className="logo" alt="" src={logo} />
    </div>
  );
}

export default App;
